.usersSearchBar {
  background-color: white;
  padding: 15px 0 15px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  max-width: 60%;
}
.usersSearchBarInput {
  line-height: 40px;
  font-size: 16px !important;
  border: 0 !important;
  background: none !important;
}
.usersSearchBarIcon {
  font-size: 28px;
  margin-right: 50px;
  margin-left: 20px;
}
.usersSearchBarTag {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #fafafa !important;
  margin-right: -10px !important;
  font-size: 16px !important;
}

.usersSearchBarButtonRow {
  z-index: 9999;
  margin-top: -46px;
  margin-bottom: 50px;
  margin-right: 41%;
}
// .usersSearchBarButtonColumn {

// }
// .usersSearchBarButton {

// }

.usersFilters {
  margin-bottom: 10px;
  display: flex;

  .filterItem {
    display: flex;
    align-items: center;

    strong {
      margin-right: 5px;
    }

    & + .filterItem {
      margin-left: 35px;
    }
  }
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;