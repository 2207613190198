body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 800px;
}

/* Password input */
.ReactPasswordStrength {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  line-height: 1.5;
  height: 36px;
  align-self: flex-start !important;
  align-content: flex-start !important;
  align-content: flex-start !important;
  justify-content: flex-start !important;
}
.ReactPasswordStrength-input {
  box-shadow: none !important;
  outline:0 !important;
  height: 34px;
}
.ReactPasswordStrength-strength-bar {
  margin-left: 3px !important;
  margin-right: auto !important;
  max-width: 98.8% !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ReactPasswordStrength-strength-desc {
  margin-top: -12px !important;
  width: 100px !important;
}
.registerBox .ReactPasswordStrength-input {
  margin-left: -18% !important;
}

/* ant design */
  /* Table defaults */
  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td,
  tr:hover > td {
      background: transparent !important;
  }
  /* inputs */
  .ant-form-item {
    margin-bottom: 14px !important;
  }
  input {
    box-shadow: none !important;
    outline:0 !important;
  }
  input:hover, input:focus, input:active {
    border-color: @redMain !important;
  }
  /* buttons */
  btn, btn-active, button, button:hover, button:focus, button:active, button:visited {
    box-shadow: none !important;
    outline:0 !important;
  }

.noStepper input[type=number]::-webkit-inner-spin-button,
.noStepper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Recaptcha */
.grecaptcha-badge {
	visibility: collapse;
}

.ant-notification {
  z-index: 9999999999 !important;
}

.ant-form-item-explain {
  text-align: left;
}
/* MFA input */
.mfaInput {
  input {
    width: 50px !important;
    height: 50px !important;
    border-width: 1px !important;
    border-color: #dedede !important;
    border-style: solid !important;
  }
}

/* Stepper ant */
div.ant-steps-item.ant-steps-item-wait
  > div.ant-steps-item-container
  > div.ant-steps-item-content
  > div.ant-steps-item-title {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-steps-icon > .ant-steps-finish-icon svg {
  margin-top: -5px !important;
}

@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;