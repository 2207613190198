body {
  padding: 0;
  min-width: 300px !important;
}

section {
  background-color: white !important;
}

/* #headers stuff */
.appHeader {
  box-shadow: 2px -1px 9px 3px rgba(0,0,0,0.2);
  z-index: 999;
  height: 80px !important;
  background-repeat: repeat-x;
  // background-color: @greyDark !important;
}
.headingLogo {
  float: left;
  width: 140px;
  height: 50px;
  margin-top: 14px;
  margin-left: -15px;
}
.userAvatar {
  margin-top: 14px !important;
}

/* Navigation */
.sideNavigation {
  padding-top: 40px !important;
}

/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
.pageContent {
  padding: 30px;
  height: 100%;
}
.pageContentSubsection {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  padding: 0 !important;
}

/* Page - fullscreen */
.pageContentFullScreen {
  padding: 30px;
  min-height: 100% !important;
}
.pageContentSubsection .ant-card-body {
  padding: 0 !important;
}
// Layout
section.container-layout {
  height: 100vh !important;
}
/* Alert */
.alertController {
  z-index: 9999;
}

/* Default logo */
.bwLogo {
  height: 50px;
  margin-bottom: 20px;
}
.bwLogo-small {
  height: 25px;
  margin-bottom: 10px;
}

/* User Badge */
.idmBadgeContainer {
  margin-right: 20px !important;
  background-color: transparent !important;
  border-color: transparent !important;
  &:hover { 
    opacity: 1 !important; 
  }
  .idmBadgeUserIcon {
    font-size: 16px !important;
    color: white !important;
    box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.1);
  }
}
@primary-color: #dedede;@link-color: #dedede;@redMain: #dedede;@greyDark: #dedede;